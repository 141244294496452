@media screen and (max-width: 1024px) {
  .mobile-menu {
    flex-direction: column;
  }
}

.logo {
  margin-left: -1rem;
}

.w-44 {
  width: 44px !important;
}
