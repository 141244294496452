body {
  min-height: 100vh;
  background-color: #f0f0f0;
}

.container {
  max-width: 900px;
  padding: 0 1rem;
}

.navbar {
  height: 80px;
}
