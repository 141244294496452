.bounce {
  animation: bounce 5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
