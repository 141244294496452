.messageInfo {
  font-size: 14px;
}

.unregistered {
  color: #adadad;
}

.active {
  border: 10px solid #a991f7;
}

.disableUserSelect {
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
  user-drag: none;
  -webkit-user-drag: none;
}
